<template>
  <validation-observer ref="validator" slim>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <validation-provider slim :name="$t('hr.expense.name')" rules="required|max:255" v-slot="{ errors }">
            <v-text-field
              v-model="expense.name"
              :disabled="loading"
              :label="$t('hr.expense.name')"
              color="tertiary"
              counter="255"
              clearable
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('hr.expense.type')" rules="required" v-slot="{ errors }">
            <expense-type-picker v-model="expense.type" :error-messages="errors" :disabled="loading" />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="9">
          <validation-provider
            slim
            :name="$t('hr.expense.amount')"
            rules="required|min_value:0|max_value:9999999"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="expense.requested.amount"
              v-decimal.unsigned
              :disabled="loading"
              :label="$t('hr.expense.amount')"
              color="tertiary"
              clearable
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="3">
          <validation-provider slim :name="$t('currency.code')" rules="required" v-slot="{ errors }">
            <currency-picker v-model="expense.requested.code" :disabled="loading" :error-messages="errors" />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('hr.expense.date')" rules="required" v-slot="{ errors }">
            <date-time-picker
              v-model="expense.timestamp"
              format="YYYY-MM-DDTHH:mm:ss"
              :disabled="loading"
              :label="$t('hr.expense.date')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="expense.paymentType"
            :disabled="loading"
            :label="$t('global.paymentType')"
            :items="paymentTypes"
            :menu-props="{
              offsetY: true
            }"
            :item-text="item => $t(`hr.expense.payment_type.${item.text}`)"
            item-value="value"
            color="tertiary"
          />
        </v-col>
        <v-col cols="12">
          <expense-management-picker
            v-model="expense.expenseManagement"
            :employee="$store.state.auth.user.id"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12">
          <file-upload-picker
            v-model="expense.attachment"
            ref="fileUpload"
            storageType="EXPENSE"
            onCreate
            :disabled="loading"
            :label="$t('hr.expense.attachment')"
          />
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('global.description')" rules="max:512" v-slot="{ errors }">
            <v-textarea
              v-model="expense.description"
              rows="1"
              :disabled="loading"
              :label="$t('global.description')"
              color="tertiary"
              :counter="512"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
    </v-container>
  </validation-observer>
</template>

<script>
  import Expense, { PaymentType } from "@/pages/hr/expense/expense/model/Expense";

  export default {
    name: "ExpenseForm",
    props: {
      status: Boolean,
      travel: Object,
      loading: Boolean
    },
    components: {
      ExpenseTypePicker: () => import("@/pages/hr/expense/expense/components/expense-type-picker"),
      CurrencyPicker: () => import("@/components/selectpickers/currency-picker"),
      ExpenseManagementPicker: () => import("@/components/selectpickers/ExpenseManagementPicker")
    },
    data: vm => ({
      expense: new Expense(),
      paymentTypes: vm.$helpers.fromEnumToArray(PaymentType)
    }),
    watch: {
      status: {
        immediate: true,
        handler(val) {
          if (val) {
            this.init();
          }
        }
      }
    },
    methods: {
      init() {
        this.expense = new Expense();
        this.expense.travel = this.travel.id;
        this.expense.employee = this.travel.employee.id;
        this.expense.reporter = this.$store.state.auth.user.id;
        if (this.$refs.fileUpload) {
          this.$refs.fileUpload.file = null;
          this.$refs.fileUpload.initialValue = null;
          this.$refs.fileUpload.oneTimeWatch = false;
        }
        if (this.$refs.validator) {
          this.$refs.validator.reset();
        }
      },
      validateAndSave() {
        return new Promise((resolve, reject) => {
          this.$refs.validator.validate().then(valid => {
            if (valid) {
              this.$refs.fileUpload.sendFile().then(uploaded => {
                if (uploaded) {
                  this.$api.expenseService
                    .save(this.expense)
                    .then(response => {                      
                      if (!response.data.error) {
                        resolve(true);
                      } else {
                        reject(response.data.error);
                      }
                    })
                    .catch(reject);
                } else {
                  resolve(false);
                }
              });
            } else {
              resolve(false);
            }
          });
        });
      }
    }
  };
</script>
