import store from "@/store";
import CurrencyModel from "@/base/model/CurrencyModel";

export default class Expense {
  constructor() {
    this.id = null;
    this.name = null;
    this.type = null;
    this.paymentType = PaymentType.OTHER;
    this.employee = store.state.auth.user.id;
    this.reporter = store.state.auth.user.id;
    this.total = null;
    this.requested = new CurrencyModel();
    this.timestamp = null;
    this.attachment = null;
    this.description = null;
    this.travel = null;
    this.expenseManagement = null;
  }
}

export const PaymentType = Object.freeze({
  CASH: "CASH",
  CREDIT_CARD: "CREDIT_CARD",
  PREPAID_CARD: "PREPAID_CARD",
  OTHER: "OTHER"
});
